import React, { useState } from "react";
import styled from "styled-components";
import plateLogo from "./images/plateLogo.png";
import PropTypes from "prop-types";
import useWindowDimensions from "./helpers/useWindowDimensions";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { ReactComponent as Logo } from "./images/logo.svg";
import wirknLogo from "./images/wirknLogo.svg";
import cupOfSugar from "./images/cupOfSugar.svg";

const PortfolioContainer = styled.div`
  min-height: 100vh;
  background-color: #21759b;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  position: relative;
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 25vw;
  color: #fffff2;
  font-family: "Montez", cursive;
  margin: 0;
  position: relative;
  font-weight: normal;
  @media (min-width: 600px) {
    font-size: 150px;
  }
`;

const GoHome = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
`;

const StyledLogo = styled(Logo)`
  fill: white;
  height: 50px;
  width: 50px;
`;

const BodyContainer = styled.main`
  display: flex;
  margin: 0px 20px;
  @media (min-width: 1025px) {
    width: 80%;
    max-width: 700px;
    margin: 0;
  }
`;

const AllProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const ProjectContainer = styled.div`
  width: 330px;
  height: 330px;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  contain: content;
  cursor: pointer;
  img {
    width: 300px;
    height: auto;
  }
  .schoodle {
    font-family: "Titillium Web", sans-serif;
    font-size: 50px;
    color: white;
  }
`;

const DetailView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  contain: content;
  color: #fffff2;
  margin: 5px;
  p {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  a {
    text-decoration: none;
    color: #fffff2;
    cursor: pointer;
    padding-top: 10px;
    :hover {
      text-decoration: underline;
    }
    :focus {
      text-decoration: underline;
    }
  }
`;

const Portfolio = () => {
  const { height } = useWindowDimensions();
  const [hoverState, setHoverState] = useState(0);
  return (
    <PortfolioContainer height={height}>
      <HeaderContainer>
        <Router forceRefresh={true}>
          <GoHome>
            <Link to="/">
              <StyledLogo />
            </Link>
          </GoHome>
        </Router>
        <Header>Portfolio</Header>
      </HeaderContainer>
      <BodyContainer>
        <AllProjectContainer>
          <ProjectContainer
            onMouseEnter={() => setHoverState(1)}
            onMouseLeave={() => setHoverState(0)}
          >
            {hoverState !== 1 && <img src={wirknLogo} alt="wirkn logo" />}{" "}
            {hoverState === 1 && (
              <DetailView>
                <p className="bold">Junior Full Stack Developer</p>
                <p>Full redesign of recruitment site for job seekers</p>
                <a href="https://beta.jobs.wirkn.com/" target="_/blank">
                  beta.jobs.wirkn.com
                </a>
              </DetailView>
            )}
          </ProjectContainer>
          <ProjectContainer
            onMouseEnter={() => setHoverState(2)}
            onMouseLeave={() => setHoverState(0)}
            className="you-decide"
          >
            {hoverState !== 2 && <img src={plateLogo} alt="app logo" />}
            {hoverState === 2 && (
              <DetailView>
                {" "}
                <p className="bold">Personal Project</p>
                <p>
                  Simple app to help make the decision of what to have for
                  dinner
                </p>
                <a href="https://meal-app-647ba.web.app/" target="_/blank">
                  Try it Now!
                </a>
              </DetailView>
            )}
          </ProjectContainer>
          <ProjectContainer
            onMouseEnter={() => setHoverState(3)}
            onMouseLeave={() => setHoverState(0)}
          >
            {hoverState !== 3 && <img src={cupOfSugar} alt="app logo" />}
            {hoverState === 3 && (
              <DetailView>
                <p className="bold">Final Group Project from BootCamp</p>
                <p>
                  Working within a team of 3 we developed a Web-App to allow
                  users to interact with their geographical neighbours. Posting
                  notices, events and items for sale.
                </p>
                <Router forceRefresh={true}>
                  <Link to="/portfolio/cup-of-sugar">More Info?</Link>
                </Router>
              </DetailView>
            )}
          </ProjectContainer>
          <ProjectContainer
            onMouseEnter={() => setHoverState(4)}
            onMouseLeave={() => setHoverState(0)}
          >
            {hoverState !== 4 && <p className="schoodle">Schoodle</p>}
            {hoverState === 4 && (
              <DetailView>
                <p className="bold">Mid-term Group Project from BootCamp</p>
                <p>
                  Working with 1 other student we developed a Web-App to allow
                  users to create events with a selection of dates and times for
                  their friends to select their preference.
                </p>
                <Router forceRefresh={true}>
                  <Link to="/portfolio/schoodle">More Info?</Link>
                </Router>
              </DetailView>
            )}
          </ProjectContainer>
        </AllProjectContainer>
      </BodyContainer>
    </PortfolioContainer>
  );
};

Portfolio.propTypes = {
  setPage: PropTypes.func,
};

export default Portfolio;
