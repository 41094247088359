import React from "react";
import styled from "styled-components";
import useWindowDimensions from "./helpers/useWindowDimensions";
import "./App.css";
import Home from "./Home";
import YouDecide from "./YouDecide";
import Portfolio from "./Portfolio";
import CupOfSugar from "./CupOfSugar";
import Schoodle from "./Schoodle";
import Resume from "./Resume";
import AboutMe from "./AboutMe";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useRouteMatch,
} from "react-router-dom";

const AppContainer = styled.div`
  min-height: ${(props) => props.windowHeight}px;
  height: 100%;
  width 100%;
  background-color: #21759b;
`;

function App() {
  const { height } = useWindowDimensions();
  return (
    <Router>
      <AppContainer windowHeight={height}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/you-decide">
            <YouDecide />
          </Route>
          <Route path="/portfolio">
            <PortfolioPage />
          </Route>
          <Route path="/resume">
            <Resume />
          </Route>
          <Route path="/about-me">
            <AboutMe />
          </Route>
        </Switch>
      </AppContainer>
    </Router>
  );
}

function PortfolioPage() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:projectId`}>
        <Project />
      </Route>
      <Route path={match.path}>
        <Portfolio />
      </Route>
    </Switch>
  );
}

function Project() {
  let { projectId } = useParams();
  if (projectId === "cup-of-sugar") {
    return <CupOfSugar />;
  } else if (projectId === "schoodle") {
    return <Schoodle />;
  } else {
    return <Portfolio />;
  }
}

export default App;
