import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useWindowDimensions from "./helpers/useWindowDimensions";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { ReactComponent as Logo } from "./images/logo.svg";
import ausBite from "./images/ausBite.jpg";
import ausHike from "./images/ausHike.jpg";
import foodMe from "./images/foodMe.jpg";
import kayak from "./images/kayak.jpg";
import scooter from "./images/scooter.jpg";
import skiing from "./images/skiing.jpg";
import scotlandHike from "./images/scotlandHike.jpg";
import winterMe from "./images/winterMe.jpg";
import cafe from "./images/cafe.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";


const AboutMeContainer = styled.div`
  min-height: 100vh;
  background-color: #21759b;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  position: relative;
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 25vw;
  color: #fffff2;
  font-family: "Montez", cursive;
  margin: 0;
  position: relative;
  font-weight: normal;
  @media (min-width: 600px) {
    font-size: 150px;
  }
`;

const GoHome = styled.div`
  top: 10px;
  left: 0;
  position: absolute;
`;

const StyledLogo = styled(Logo)`
  fill: white;
  height: 50px;
  width: 50px;
`;

const BodyContainer = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 71px;
  max-width: 700px;
`;

const BodyText = styled.div`
  color: #fffff2;
  font-size: 1rem;
  margin: 0;
  padding: 0 7.5px;
  height: auto;
  p {
    margin: 0;
  }
  .bold {
    font-weight: bold;
  }
`;

const OuterImagesContainer = styled.div`
  display: flex;
  height: auto;
  width: auto;
  background-color: #21759b;
  justify-content: space-around;
  margin: 40px 0;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
  &.hidden {
    display: none;
    border: none;
    img {
      display: none;
    }
  }
  img {
    height: 150px;
    width: 150px;
    border-radius: 4px;
    object-fit: cover;
  }
  :hover {
    img {
      opacity: 0.3;
    }
  }
  @media (min-width: 768px) {
    height: 200px;
    width: 200px;
    border-radius: 4px;
    &.hidden {
      display: block;
      img {
        display: block;
      }
    }
    img {
      height: 200px;
      width: 200px;
      border-radius: 4px;
      object-fit: cover;
    }
  }
`;

const ImageText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  color: #fffff2;
  p {
    margin-top: 80px;
  }
  .location {
    font-size: 0.75rem;
    margin-top: 0;
  }
  :hover {
    opacity: 1;
  }
`;

const AboutMe = () => {
  const { height } = useWindowDimensions();
  return (
    <AboutMeContainer height={height}>
      <Router forceRefresh={true}>
        <HeaderContainer>
          <GoHome>
            <Link to="/">
              <StyledLogo />
            </Link>
          </GoHome>
          <Header>About Me</Header>
        </HeaderContainer>
        <BodyContainer>
          <BodyText>
            <p>
              Originally from Perth, Western Australia, and having lived in
              Melbourne, Rome, and Edinburgh, and travelled throughout Europe
              and SE Asia, I finally visited and fell in love with Montreal.
            </p>
          </BodyText>
          <OuterImagesContainer>
            <ImageContainer>
              <img src={ausBite} alt="Great Australian Bite" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} /> <span> the beach</span>
                </p>
                <p className="location">The Great Australian Bite</p>
              </ImageText>
            </ImageContainer>
            <ImageContainer className="hidden">
              <img src={foodMe} alt="me with food" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} /> <span> food</span>
                </p>
                <p className="location">Berlin, Germany</p>
              </ImageText>
            </ImageContainer>
            <ImageContainer>
              <img src={cafe} alt="cafe in Laos" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} /> <span> travelling</span>
                </p>
                <p className="location">Luang Prabang, Laos</p>
              </ImageText>
            </ImageContainer>
          </OuterImagesContainer>
          <BodyText>
            <p>
              Having starting my career in Human Resources, I discovered the
              joys of coding and have not looked back!
            </p>
            <br />
            <p>
              Web Development is a perfect fit for me, combining my interest in
              understanding people and their problems, with the satisfaction of
              building practical solutions.
            </p>
          </BodyText>
          <OuterImagesContainer>
            <ImageContainer>
              <img src={skiing} alt="me in Ski gear" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} /> <span> skiing</span>
                </p>
                <p className="location">Mt Tremblant, Quebec</p>
              </ImageText>
            </ImageContainer>
            <ImageContainer className="hidden">
              <img src={ausHike} alt="Australian bush track" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} />{" "}
                  <span> the outdoors</span>
                </p>
                <p className="location">Dwellingup, Western Australia</p>
              </ImageText>
            </ImageContainer>
            <ImageContainer>
              <img src={kayak} alt="Kayak" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} />{" "}
                  <span> water sports</span>
                </p>
                <p className="location">HaLong Bay, Vietnam</p>
              </ImageText>
            </ImageContainer>
          </OuterImagesContainer>
          <BodyText>
            <p>
              This combination of social, creative, and analytical skills is the
              perfect mix, allowing me to both focus on the fine detail of
              challenging hands-on technical problems, and see the bigger
              picture of helping bring solutions to life.
            </p>
            <br />
            <p>
              I love the close collaboration with related disciplines such as
              design, and even enjoy bug hunting!
            </p>
          </BodyText>
          <OuterImagesContainer>
            <ImageContainer>
              <img src={scooter} alt="people on scooters" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} /> <span> adventure</span>
                </p>
                <p className="location">Saigon, Vietnam</p>
              </ImageText>
            </ImageContainer>
            <ImageContainer className="hidden">
              <img src={scotlandHike} alt="me on top of a hill" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} /> <span> hiking</span>
                </p>
                <p className="location">The Cobbler, Scotland</p>
              </ImageText>
            </ImageContainer>
            <ImageContainer>
              <img src={winterMe} alt="me in the snow" />
              <ImageText>
                <p>
                  I <FontAwesomeIcon icon={faHeart} /> <span> winter</span>
                </p>
                <p className="location">Home, Montreal</p>
              </ImageText>
            </ImageContainer>
          </OuterImagesContainer>
          <BodyText>
            <p>
              As with travelling, there is always so much more to learn and
              discover, to improve your code and yourself. I’m excited to see
              where my web development future will take me!
            </p>
          </BodyText>
        </BodyContainer>
      </Router>
    </AboutMeContainer>
  );
};

AboutMe.propTypes = {
  setPage: PropTypes.func,
};

export default AboutMe;
