import React from "react";
import styled from "styled-components";
import plateLogo from "./images/plateLogo.png";
import PropTypes from "prop-types";
import useWindowDimensions from "./helpers/useWindowDimensions";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { ReactComponent as Logo } from "./images/logo.svg";

const HomePageContainer = styled.div`
  height: ${(props) => props.height}px;
  width: 100vw;
  background-color: #21759b;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
`;

const GoHome = styled.div`
  position: fixed;
  top: 10px;
  left: 0;
`;

const StyledLogo = styled(Logo)`
  fill: white;
  height: 50px;
  width: 50px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 90vh;
`;

const TitleContainer = styled.div`
  font-size: 4rem;
  color: #fffff2;
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0;
  }
  @media (min-width: 768px) {
    font-size: 10rem;
  }
`;

const AppLinkContainer = styled.a`
  font-size: 1.5rem;
  color: #fffff2;
  font-family: "Raleway", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const AppLogo = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 5px;
  border: 1px solid white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  img {
    height: 25px;
    width: 25px;
  }
  @media (min-width: 768px) {
    height: 40px;
    width: 40px;
    img {
      height: 35px;
      width: 35px;
    }
  }
`;

const TextContainer = styled.div`
  border: 1px solid white;
  height: fit-content;
  width: 90vw;
  background: white;
  padding: 5px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .li-header {
    font-weight: bold;
    padding-top: 15px;
  }
`;

const YouDecide = () => {
  const { height } = useWindowDimensions();
  return (
    <HomePageContainer height={height}>
      <Router forceRefresh={true}>
        <GoHome>
          <Link to="/">
            <StyledLogo />
          </Link>
        </GoHome>
      </Router>
      <BodyContainer>
        <TitleContainer>
          <p>You Decide</p>
        </TitleContainer>
        <TextContainer>
          <p>Can't decide what to have for dinner tonight?</p>
          <p>You Decide is a simple app full of easy to make meal ideas</p>
          <p>
            Feel free to add you own meals or let it help you decide what to
            make for your dinner.
          </p>
          <p className="li-header">
            A few quick notes before you get started...
          </p>
          <ul>
            <li>
              The meals in the app are shared amongst all users, therefore
              anything you add or delete will affect other users.
            </li>
            <li>
              It's a work in progress so there will be bugs! Please ensure you
              have a good internet connection and if something breaks try
              refreshing the page.
            </li>
            <li>If you have any feedback feel free to message me</li>
          </ul>
        </TextContainer>
        <AppLinkContainer
          href="https://meal-app-647ba.web.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppLogo>
            <img src={plateLogo} alt="app logo of plate" />{" "}
          </AppLogo>
          Get Started
        </AppLinkContainer>
      </BodyContainer>
    </HomePageContainer>
  );
};

YouDecide.propTypes = {
  setPage: PropTypes.func,
};

export default YouDecide;
