import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useWindowDimensions from "./helpers/useWindowDimensions";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { ReactComponent as Logo } from "./images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";

const ResumeContainer = styled.div`
  min-height: 100vh;
  background-color: #21759b;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  position: relative;
  a {
    :hover {
      text-decoration: underline;
    }
    :focus {
      text-decoration: underline;
    }
  }
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 25vw;
  color: #fffff2;
  font-family: "Montez", cursive;
  margin: 0;
  position: relative;
  font-weight: normal;
  @media (min-width: 600px) {
    font-size: 150px;
  }
`;

const PrintIcon = styled.a`
  color: #fffff2;
  font-size: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const GoHome = styled.div`
  top: 10px;
  left: 0;
  position: absolute;
`;

const StyledLogo = styled(Logo)`
  fill: white;
  height: 50px;
  width: 50px;
`;

const BodyContainer = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 40px;
  max-width: 700px;
`;

const SubHeading = styled.h2`
  background-color: #21759b;
  color: #fffff2;
  font-size: 1.5rem;
  padding: 5px;
  margin: 0;
  text-align: center;
`;

const BodyText = styled.div`
  color: #fffff2;
  font-size: 1rem;
  margin: 0;
  padding: 0 7.5px 5px 7.5px;
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    padding: 2px;
    padding-bottom: 10px;
  }
  p {
    margin: 0;
  }
  .summary {
    margin: 16px 0;
    text-align: justify;
  }
  .bold {
    font-weight: bold;
    padding-bottom: 2px;
  }
  a {
    text-decoration: none;
    color: #fffff2;
  }
  .expand {
    font-weight: bold;
    padding: 0 5px;
    font-size: 22px;
    cursor: pointer;
    border: none;
    background-color: #21759b;
    color: #fffff2;
    :focus {
      outline: none;
    }
  }
`;

const YouDecide = () => {
  const [expandRoles, setExpandRoles] = useState(false);
  const { height } = useWindowDimensions();
  return (
    <ResumeContainer height={height}>
      <Router forceRefresh={true}>
        <HeaderContainer>
          <GoHome>
            <Link to="/">
              <StyledLogo />
            </Link>
          </GoHome>
          <Header>Nelly Main</Header>
          <PrintIcon
            href="https://drive.google.com/open?id=1Fjt-Z4mMj8KvDIYSxsdPUzGWvVce7YbE"
            target="_/blank"
            aria-label="print CV"
          >
            <FontAwesomeIcon icon={faPrint} />
          </PrintIcon>
        </HeaderContainer>
        <BodyContainer>
          <SubHeading>Summary</SubHeading>
          <BodyText>
            <p className="summary">
              A junior developer with professional experience shipping products,
              I enjoy building practical solutions to real-world problems. Web
              Development has proven to be a perfect fit, combining my interest
              in understanding people and their problems, with the satisfaction
              of losing myself in the fine detail of solving challenging
              hands-on technical problems.
            </p>
            <p>
              Always looking to improve myself and my code, I am looking for
              opportunities where I can both contribute and learn.
            </p>
          </BodyText>
          <SubHeading>Skills</SubHeading>
          <BodyText>
            <ul>
              <li>
                <p>
                  <span className="bold">Languages: </span> Javascript, Ruby,
                  SASS, CSS, HTML5
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Libraries/Frameworks: </span> ReactJS,
                  ExpressJS, Ruby on Rails, NodeJs, jQuery
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Databases: </span> PostgreSQL, SQL,
                  ActiveRecord, Firebase
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Other: </span> MapBox GL, Browser
                  Stack, Git/Github, Zeplin, Jira, Storybook, Responsive Web
                  Design, Team Projects, Peer Code Reviews, Agile Methodologies
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">HR/Recruitment </span> Interviewing,
                  Assessment Centers, Training, HRIS, Remuneration and Benefits,
                  Reference Checking, Diversity and Inclusion, Performance
                  Management, Planning, Process Implemention, Reporting, Data
                  Analysis, Surveys, Mentoring, Project Management
                </p>
              </li>
            </ul>
          </BodyText>
          <SubHeading>Work History</SubHeading>
          <BodyText>
            <ul>
              <li>
                <p className="bold">Junior Full Stack Developer </p>
                <a
                  className="bold"
                  href="https://www.wirkn.com/"
                  target="_/blank"
                >
                  Wirkn - Montreal
                </a>
                <p className="bold">Nov 2019 - Present </p>
                <p>
                  Working together within a 9 person team of local and remote
                  developers and designers using agile methodologies I helped to
                  release a new recruitment
                </p>
              </li>
              <li>
                <p className="bold">Talent Acquisition Executive </p>
                <a
                  className="bold"
                  href="https://www.skyscanner.ca/about-us"
                  target="_/blank"
                >
                  Skyscanner - Edinburgh, UK
                </a>
                <p className="bold">Mar 2018 - Sept 2018 </p>
                <p>
                  Working at the head office of this leading global travel
                  search site and Scotland’s first Unicorn, within a dispersed
                  team I supported the recruitment activities across all
                  business areas internationally.
                </p>
              </li>
              <li>
                <p className="bold">HR/Office Coordinator </p>
                <a
                  className="bold"
                  href="https://www.buildarocketboy.com/"
                  target="_/blank"
                >
                  Build a Rocket Boy - Edinburgh, UK{" "}
                </a>
                <p className="bold">Mar 2017 - Jan 2018 </p>
                <p>
                  During the start up phase of a video game development company
                  I set up and ran all office, recruitment, on-boarding and HR
                  procedures.
                </p>
              </li>
              {!expandRoles ? (
                <li>
                  <p className="bold">
                    Human Resources
                    <button
                      className="expand"
                      onClick={() => setExpandRoles(!expandRoles)}
                      onKeyDown={() => setExpandRoles(!expandRoles)}
                    >
                      {expandRoles ? `-` : `+`}
                    </button>
                  </p>
                  <p className="bold">Various - Australia </p>
                  <p className="bold">Mar 2010 - Dec 2015 </p>
                  <p>
                    Generalist HR within an IT Solutions and Services
                    Multinational, Disability Services Not for Profit and a
                    Government Department.
                  </p>
                </li>
              ) : (
                <>
                  <li>
                    <p className="bold">
                      HR Consultant &#38; Recruiter, Special Projects
                      <button
                        className="expand"
                        onClick={() => setExpandRoles(!expandRoles)}
                        onKeyDown={() => setExpandRoles(!expandRoles)}
                      >
                        {expandRoles ? `-` : `+`}
                      </button>
                    </p>
                    <a
                      href="https://mondo.com.au/business"
                      target="_/blank"
                      className="bold"
                    >
                      Mondo (formerly Select Solutions) - Melbourne, Australia
                    </a>
                    <p className="bold">Jan 2013 - Dec 2015 </p>
                    <p>
                      For a 6 month maternity leave cover I was the main HR
                      contact for 3 business units, supporting managers across
                      the entire employment lifecycle.
                    </p>
                    <br />
                    <p>
                      I then transferred to an in-house project recruitment
                      consultant role supporting larger in-sourcing and
                      mobilisation projects
                    </p>
                  </li>
                  <li>
                    <p className="bold">HR Advisor</p>
                    <a
                      href="https://www.nec.com.au/about"
                      target="_/blank"
                      className="bold"
                    >
                      NEC - Perth &#38; Melbourne, Australia
                    </a>
                    <p className="bold">Jan 2013 - Dec 2015 </p>
                    <p>
                      As the only HR contact in Western Australia I worked
                      closely with Managers to provide advice and assistance
                      with all aspects of HR for 180 employees across 3 offices.
                    </p>
                    <br />
                    <p>
                      I then transferred to the head office in Melbourne where I
                      supported 320 corporate and IT professionals in our
                      Melbourne and South Australian offices in all HR related
                      areas.
                    </p>
                  </li>
                  <li>
                    <p className="bold">HR Officer</p>
                    <a
                      href="https://www.activ.asn.au/who-we-are/our-purpose/"
                      target="_/blank"
                      className="bold"
                    >
                      Activ Foundation - Perth, Australia
                    </a>
                    <p className="bold">Jan 2013 - Dec 2015 </p>
                    <p>
                      I provided HR and recruitment advice and assistance to 200
                      corporate professionals, carers and workshop managers
                      employed to support people with disabilities.
                    </p>
                  </li>
                </>
              )}
            </ul>
          </BodyText>
          <SubHeading>Education</SubHeading>
          <BodyText>
            <ul>
              <li>
                <a
                  href="https://www.lighthouselabs.ca/en/web-bootcamp"
                  target="_/blank"
                >
                  <span className="bold">LightHouse Labs </span>Web Development
                  Bootcamp
                </a>
              </li>
              <li>
                <a
                  href="https://www.uwa.edu.au/study/courses/bachelor-of-commerce"
                  target="_/blank"
                >
                  <span className="bold">University of Western Australia </span>
                  BA Arts and Commerce (Communications, HR &#38; Anthropology)
                </a>
              </li>
              <li>
                <span className="bold">Selmar Institute of Education </span>
                Certificate IV in Training &#38; Assessment
              </li>
            </ul>
          </BodyText>
          <SubHeading>Projects</SubHeading>
          <BodyText>
            <ul>
              <li>
                <a className="bold" href="https://jobs.wirkn.com/">
                  Wirkn (Beta)
                </a>
                <p>
                  A recruitment platform for users looking for employment in
                  retail.
                </p>
                <p>
                  Stack &#38; Tools: React, Storybook, NodeJs, NextJs, Uppy,
                  MapBox, Express.
                </p>
              </li>
              <li>
                <Link to="you-decide" className="bold">
                  You Decide
                </Link>
                <p>
                  Personal Project - An app to randomly suggest simple meal
                  ideas.
                </p>
                <p>
                  Stack &#38; Tools: React, Firebase - image storage, database
                  and hosting.
                </p>
              </li>
              <li>
                <Router forceRefresh={true}>
                  <Link className="bold" to="/portfolio/cup-of-sugar">
                    Cup of Sugar
                  </Link>
                </Router>
                <p>
                  Bootcamp Group Project - A social platform encouraging users
                  to connect with their geographical neighbours.
                </p>
                <p>
                  Stack &#38; Ruby on Rails, Postgres, React, Google Maps API,
                  SASS.
                </p>
              </li>
              <li>
                <a
                  className="bold"
                  href="https://github.com/n3llym/jungle_rails"
                >
                  Jungle
                </a>
                <p>Bootcamp Project - A mini e-commerce app</p>
                <p>
                  Stack &#38; Tools: Ruby on Rails, PostgreSQL, RSpec, Capybara.
                </p>
              </li>
              <li>
                <Router forceRefresh={true}>
                  <Link to="/portfolio/scheduler" className="bold">
                    Schoodle
                  </Link>
                </Router>
                <p>
                  Bootcamp Group Project - A scheduling app similar to Doodle
                </p>
                <p>
                  Stack &#38; Tools: React, Webpack, Babel, Axios, Storybook,
                  Jest.{" "}
                </p>
              </li>
              <li>
                <a className="bold" href="https://github.com/n3llym/scheduler">
                  Scheduler
                </a>
                <p>
                  Bootcamp Project - Interview scheduling app allowing users to
                  book and cancel interview appointments
                </p>
                <p>Stack &#38; Tools: ExpressJS, jQuery, SASS, Postgres.</p>
              </li>
            </ul>
          </BodyText>
          <SubHeading>Volunteering</SubHeading>
          <BodyText>
            <ul>
              <li>
                Personal Assistant to people with Disabilities -{" "}
                <a
                  href="https://www.thistle.org.uk/who-we-are"
                  target="_/blank"
                >
                  Thistle Foundation
                </a>
              </li>
              <li>Live-in Au Pair, Rome, Italy</li>
              <li>
                English Teacher -{" "}
                <a
                  href="https://immi.homeaffairs.gov.au/settling-in-australia/amep/about-the-program"
                  target="_/blank"
                >
                  Australia Migrant English Program
                </a>
              </li>
              <li>
                Weekend Camp volunteer for adults with Disabilities -{" "}
                <a
                  href="https://www.activ.asn.au/how-you-can-help/volunteering/"
                  target="_/blank"
                >
                  Activ Foundation
                </a>
              </li>
              <li>
                Children Respite Volunteers -{" "}
                <a
                  href="https://www.activ.asn.au/how-you-can-help/volunteering/"
                  target="_/blank"
                >
                  {" "}
                  Activ Foundation
                </a>
              </li>
            </ul>
          </BodyText>
        </BodyContainer>
      </Router>
    </ResumeContainer>
  );
};

YouDecide.propTypes = {
  setPage: PropTypes.func,
};

export default YouDecide;
