import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { ReactComponent as Logo } from "./images/logo.svg";
import cosLogin from "./images/cosLogin.png";
import cosMainPage from "./images/cosMainPage.png";
import cosNewNotice from "./images/cosNewNotice.png";

const PortfolioContainer = styled.div`
  min-height: 100vh;
  background-color: #21759b;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 70px;
  color: #fffff2;
  font-family: "Montez", cursive;
  margin: 0;
  position: relative;
  @media (min-width: 799px) {
    font-size: 100px;
  }
`;

const GoHome = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
`;

const StyledLogo = styled(Logo)`
  fill: white;
  height: 50px;
  width: 50px;
`;

const BodyContainer = styled.div`
  display: flex;
  margin: 0px 20px;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
  }
`;

const SubHeading = styled.h2`
  background-color: #21759b;
  color: #fffff2;
  font-size: 1.5rem;
  padding: 5px;
  margin-top: 15px;
  font-weight: bold;
  text-align: center;
`;

const ScreenShot = styled.div`
  contain: content;
  width: 90%;
  margin-bottom: 25px;
  img {
    width: 100%;
    max-width: 700px;
    height: auto;
  }
`;

const cupOfSugar = () => {
  return (
    <PortfolioContainer>
      <HeaderContainer>
        <Router forceRefresh={true}>
          <GoHome>
            <Link to="/portfolio">
              <StyledLogo />
            </Link>
          </GoHome>
        </Router>
        <Header>Cup Of Sugar</Header>
      </HeaderContainer>
      <BodyContainer>
        <a href="https://www.facebook.com/lighthouselabsmontreal/videos/763729784097328/?epa=SEARCH_BOX">
          <SubHeading>Project Presentation Video</SubHeading>
        </a>

        <a href="https://github.com/n3llym/Cup_of_Sugar">
          <SubHeading>GitHub Repository</SubHeading>
        </a>
        <ScreenShot>
          <img src={cosLogin} alt="screenshot of Login" />
        </ScreenShot>
        <ScreenShot>
          <img src={cosMainPage} alt="screenshot of MainPage" />
        </ScreenShot>
        <ScreenShot>
          <img src={cosNewNotice} alt="screenshot of notice page" />
        </ScreenShot>
      </BodyContainer>
    </PortfolioContainer>
  );
};

cupOfSugar.propTypes = {
  setPage: PropTypes.func,
};

export default cupOfSugar;
