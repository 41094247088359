import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "./images/logo.svg";
import linkedIn from "./images/linkedIn.svg";
import gitHubWhite from "./images/gitHubWhite.png";
import instagram from "./images/instagram.png";
import PropTypes from "prop-types";
import useWindowDimensions from "./helpers/useWindowDimensions";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";

const HomePageContainer = styled.div`
  height: 100%;
  min-height: ${props => props.windowHeight}px;
  background-color: #21759b;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const Header = styled.nav`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 700px;
  a {
    text-decoration: none;
  }
`;

const Tab = styled.h3`
  color: #fffff2;
  font-weight: normal;
`;

const Footer = styled.footer`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  background-color: #21759b;
`;

const FooterIconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21759b;
  img {
    height: 30px;
    width: auto;
    margin-right: 20px;
  }
  .linkedIn {
    margin-right: 14px;
  }
  .instagram {
    filter: invert(100%);
    filter: xray;
  }
`;

const EmailIcon = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-right: 20px;
`;

const BodyContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 50px 0;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .heading {
    font-size: 20vw;
    color: #fffff2;
    font-family: "Montez", cursive;
    margin: 5px 0;
    font-weight: normal;
    @media (min-width: 1025px) {
      font-size: 200px;
    }
  }
  .sub-heading {
    font-size: 5vw;
    color: #fffff2;
    margin: 5px 0;
    font-weight: normal;
    @media (min-width: 1025px) {
      font-size: 50px;
    }
  }
`;

const StyledLogo = styled(Logo)`
  height: 100px;
  width: 100px;
  margin-bottom: 15px;
  fill: white;
`;

const Home = () => {
  const { height } = useWindowDimensions();
  return (
    <HomePageContainer windowHeight={height}>
      <Header>
        <Router forceRefresh={true}>
          <Link to="portfolio">
            <Tab>Portfolio</Tab>
          </Link>
          <Link to="resume">
            <Tab>Resume</Tab>
          </Link>
          <Link to="about-me">
            <Tab>About Me</Tab>
          </Link>
        </Router>
      </Header>
      <BodyContainer>
        <TitleContainer>
          <h1 className="heading">Nelly Main</h1>
          <h2 className="sub-heading">Web Developer</h2>
        </TitleContainer>
        <StyledLogo />
      </BodyContainer>
      <Footer>
        <FooterIconContainer>
          <a
            href="mailto:nelly_main@icloud.com"
            target="_/blank"
            aria-label="email me"
          >
            <EmailIcon>
              <FontAwesomeIcon
                icon={faEnvelope}
                color="#fffff2"
                alt="email icon"
              />
            </EmailIcon>
          </a>
          <a href="https://github.com/n3llym" target="_/blank">
            <img className="gitHub" src={gitHubWhite} alt="github logo" />
          </a>
          <a
            href="https://www.linkedin.com/in/nelly-main-1ab04024/"
            target="_/blank"
          >
            <img className="linkedIn" src={linkedIn} alt="linkedin logo" />
          </a>
          <a href="https://www.instagram.com/nellysays/" target="_/blank">
            <img className="instagram" src={instagram} alt="instagram logo" />
          </a>
        </FooterIconContainer>
      </Footer>
    </HomePageContainer>
  );
};

Home.propTypes = {
  setPage: PropTypes.func,
};

export default Home;
