import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { ReactComponent as Logo } from "./images/logo.svg";
import createView from "./images/scheduleCreate.png";
import attendeeView from "./images/schedulerAttendeeView.png";
import organiserView from "./images/schedulerOrganiserView.png";

const PortfolioContainer = styled.div`
  min-height: 100vh;
  background-color: #21759b;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.p`
  font-size: 100px;
  color: #fffff2;
  font-family: "Montez", cursive;
  margin: 0;
  position: relative;
  @media (min-width: 1025px) {
    font-size: 100px;
  }
`;

const GoHome = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
`;

const StyledLogo = styled(Logo)`
  fill: white;
  height: 50px;
  width: 50px;
`;

const BodyContainer = styled.div`
  display: flex;
  margin: 0px 20px;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
  }
`;

const SubHeading = styled.p`
  background-color: #21759b;
  color: #fffff2;
  font-size: 1.5rem;
  padding: 5px;
  margin-top: 15px;
  font-weight: bold;
  text-align: center;
`;

const ScreenShot = styled.div`
  contain: content;
  width: 90%;
  margin-bottom: 25px;
  img {
    width: 100%;
    max-width: 700px;
    height: auto;
  }
`;

const Scheduler = () => {
  return (
    <PortfolioContainer>
      <HeaderContainer>
        <Router forceRefresh={true}>
          <GoHome>
            <Link to="/portfolio">
              <StyledLogo />
            </Link>
          </GoHome>
        </Router>
        <Header>Schoodle</Header>
      </HeaderContainer>
      <BodyContainer>
        <a href="https://github.com/n3llym/schoodle">
          <SubHeading>GitHub Repository</SubHeading>
        </a>
        <SubHeading>ScreenShots</SubHeading>
        <ScreenShot>
          <img src={createView} alt="screenshot of create page" />
        </ScreenShot>
        <ScreenShot>
          <img src={organiserView} alt="screenshot of organiser view" />
        </ScreenShot>
        <ScreenShot>
          <img src={attendeeView} alt="screenshot of attendee view" />
        </ScreenShot>
      </BodyContainer>
    </PortfolioContainer>
  );
};

Scheduler.propTypes = {
  setPage: PropTypes.func,
};

export default Scheduler;
